import axios from "axios";
import localforage from "localforage";
import Cookie from "./Cookie";

interface ApiResponse {
  data: any;
  error: string | null;
}

const CACHE_EXPIRATION_TIME = import.meta.env.CACHE_EXPIRATION_TIME; // 2 horas en milisegundos

class TimestampedData {
  data: any;
  timestamp: number;
  constructor(data: any, timestamp: number) {
    this.data = data;
    this.timestamp = timestamp;
  }
}

const do_not_cache = [
  "/login/",
  "/register/",
  "/validate-mail/",
  "/braintree-token/",
];
export class OrizonApiClient {
  setToken(token: string) {
    axios.defaults.params = {};
    axios.defaults.params["token"] = token;
  }
  async get(endpoint: string, raw: Boolean = false): Promise<ApiResponse> {
    if (!raw) {
      endpoint = "orizon" + endpoint;
    }
    // @ts-ignore
    if (window.clarity_user_id !== undefined) {
      if (endpoint.includes("?")) {
        // @ts-ignore
        endpoint += "&clarity_user_id=" + window.clarity_user_id;
      } else {
        // @ts-ignore
        endpoint += "?clarity_user_id=" + window.clarity_user_id;
      }
    }
    try {
      if (!do_not_cache.some((prefix) => endpoint.startsWith(prefix))) {
        const cachedData: TimestampedData = (await localforage.getItem(
          endpoint
        )) as TimestampedData;
        if (
          cachedData !== null &&
          Date.now() - cachedData.timestamp < CACHE_EXPIRATION_TIME
        ) {
          return {
            data: cachedData.data,
            error: null,
          };
        }
      }

      const response: ApiResponse = await axios.get(endpoint);
      const store = {
        data: response.data,
        timestamp: Date.now(),
      };
      await localforage.setItem(endpoint, store, (err) => {
        if (err) {
          console.error(err);
        }
      });
      return {
        data: response.data,
        error: null,
      };
    } catch (error) {
      return {
        data: null,
        // @ts-ignore
        error: error.message,
      };
    }
  }

  async post(endpoint: string, data: any): Promise<ApiResponse> {
    endpoint = "orizon/" + endpoint;

    const taRef = Cookie.getCookie("ta_ref");

    if (taRef !== "") {
      if (endpoint.includes("?")) {
        endpoint += "&ref=" + taRef;
      } else {
        endpoint += "?ref=" + taRef;
      }
    }

    // @ts-ignore
    if (window.clarity_user_id !== undefined) {
      if (endpoint.includes("?")) {
        // @ts-ignore
        endpoint += "&clarity_user_id=" + window.clarity_user_id;
      } else {
        // @ts-ignore
        endpoint += "?clarity_user_id=" + window.clarity_user_id;
      }
    }
    try {
      const response: ApiResponse = await axios.post(endpoint, data);
      return {
        data: response.data,
        error: null,
      };
    } catch (error) {
      return {
        data: null,
        // @ts-ignore
        error: error.message,
      };
    }
  }

  async validateEmail(email: string): Promise<ApiResponse> {
    return this.post("/validate-mail/", { email: email });
  }

  async registerUser(
    email: string,
    password: string,
    name: string,
    flMark: string
  ): Promise<ApiResponse> {
    return this.post("/register/", {
      email: email,
      password: password,
      name: name,
      flMark: flMark,
    });
  }

  async login(email: string, password: string): Promise<ApiResponse> {
    return this.post("/login/", { email: email, password: password }).then(
      (response) => {
        if (response.data) {
          return response;
        } else {
          return {
            data: null,
            error: "Usuario o contraseña incorrectos",
          };
        }
      }
    );
  }

  async googleLogin(email: string, googleid: string): Promise<ApiResponse> {
    return this.post("/google-login/", {
      email: email,
      googleid: googleid,
    }).then((response) => {
      if (response.data) {
        return response;
      } else {
        return {
          data: null,
          error: "login.social_login_error",
        };
      }
    });
  }

  async recoverPassword(email: string): Promise<ApiResponse> {
    return this.post("recover-password/", { email: email });
  }

  async getCountryAssets(country: string): Promise<ApiResponse> {
    country = country.replace("ñ", "n");
    return this.get(`/country-assets/${country}`);
  }

  async getBraintreeToken(token: string) {
    return this.get("/braintree-token/" + token).then(
      (response) => response.data
    );
  }

  async payWithBraintree(payload: any) {
    console.log(payload);
    return this.post("/braintree-pay/", { payload: payload }).then(
      (response) => response.data
    );
  }

  async validatePromoCode(promoCode: string) {
    return this.get("/validate-promo/" + promoCode).then(
      (response) => response.data
    );
  }
}
